<template>
	<div>
		<div class="card card-custom card-stretch gutter-b" style="min-height: 68em;">
			<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 60px">
				<div>
					<h3 class="card-title align-items-start flex-column">
						<span class="card-label font-weight-bolder"> Accounts </span>
					</h3>
				</div>
				<div class="card-toolbar">
					<div class="search-box">
						<b-form-input class="search-bar" size="sm" v-model="searchText" placeholder="Search" debounce="500" />
						<b-icon-search class="search-icon" />
					</div>
					<router-link :to="{ name: 'adminnewaccount' }">
						<button class="btn btn-light-primary font-weight-bolder text-uppercase px-5 py-2 ml-5">Create New Account</button>
					</router-link>
				</div>
			</div>

			<div class="card-body pt-2 pb-0">
				<!-- style="max-height: 800px; overflow-y: none" -->
				<ITCSpinner :loaded="loaded">
					<!--begin::Table-->
					<b-table
						striped
						hover
						:items="filteredAccounts"
						:fields="fields"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						:current-page="currentPage"
						:per-page="perPage"
						sort-icon-left
						@row-clicked="rowClickHandler"
						show-empty
						fixed
						responsive
					>
						<template #table-colgroup>
							<col style="width: 10%;" />
							<col style="max-width: 20%" />
							<col style="max-width: 20%" />
							<col style="width: 10%" />
							<col style="width: 20%" />
							<col style="width: 10%" />
							<col style="width: 10%" />
						</template>
						<template #cell(AccountID)="data">
							<div class="d-flex align-items-center">
								<div class="symbol symbol-50 symbol-light">
									<span class="symbol-label">
										<inline-svg v-if="!data.item.avatar" src="/media/svg/icons/Communication/Contact1.svg" />
										<img v-if="data.item.avatar" :src="data.item.avatar" style="max-height: 100%; max-width: 100%;" />
									</span>
								</div>
							</div>
						</template>
						<template #cell(username)="data">
							<div class="d-flex align-items-center">
								<div>
									<a
										v-if="data.item.role == 'user' || data.item.role == 'useradmin'"
										@click="
											clickViewAs({
												AccountID: data.item.AccountID,
												name: data.item.name,
												username: data.item.username,
												avatar: data.item.avatar,
											})
										"
										class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
										>{{ data.item.username }}</a
									>

									<span v-if="data.item.role != 'user' && data.item.role != 'useradmin'" class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
										data.item.username
									}}</span>

									<span class="text-muted font-weight-bold d-block">{{ data.item.AccountID }}</span>
								</div>
							</div>
						</template>
						<template #cell(name)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg">
								{{ data.item.name }}
							</span>
							<span class="text-muted font-weight-bold">{{ data.item.email }}</span>
						</template>
						<template #cell(lastlogin)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg">
								{{ data.item.lastlogin }}
							</span>
							<span class="text-muted font-weight-bold">{{ data.item.DateAdded }}</span>
						</template>
						<template #cell(sitecount)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg text-center">
								<b-badge>{{ data.item.sitecount }}</b-badge>
							</span>
						</template>
						<template #cell(actions)="data">
							<div style="min-width:50px;" class="text-right">
								<span class="text-dark-75 font-weight-bolder d-block font-size-lg">
									<router-link :to="{ name: 'admineditaccount', params: { id: data.item.AccountID } }">
										<div class="fa fa-edit"></div>
									</router-link>
									<i v-b-modal="'myModal' + data.item.AccountID" class="far fa-trash-alt" style="color: red; margin: 0 10px;"></i>
									<b-modal
										:id="'myModal' + data.item.AccountID"
										title="Delete Account"
										ok-title="Delete"
										ok-variant="danger"
										@ok="deleteAccount(data.item.AccountID)"
									>
										<p class="my-4">
											Are you sure you want to delete account with username <b>{{ data.item.name }}</b> ({{ data.item.username }})?
										</p>
										<p class="my-4 text-muted">
											Please note the username will continue to be reserved though the account will be inaccessible. Consider changing the username if
											you would like to use this username in the future.
										</p>
									</b-modal>
								</span>
							</div>
						</template>
					</b-table>
				</ITCSpinner>
			</div>
			<div class="footer" v-if="filteredAccounts.length > 0 && accounts.length > perPage">
				<span v-if="loaded">{{ filteredAccounts.length }} accounts</span>
				<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredAccounts.length" :per-page="perPage" />
			</div>
		</div>
	</div>
</template>

<script>
import { SET_READY_STATE } from '@/core/services/store/ready.module';
import { mapState, mapGetters } from 'vuex';
import { ENABLE_VIEWAS } from '@/core/services/store/viewas.module';
import swMixin from '@/core/services/mixins/serviceworker.mixin';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';
import router from '@/router';

export default {
	name: 'AccountList',
	mixins: [swMixin],
	data() {
		return {
			accounts: [],
			perPage: 10,
			fields: [
				{ key: 'AccountID', label: '', sortable: true, sortDirection: 'desc', class: 'align-middle' },
				{ key: 'username', sortable: true, class: 'align-middle' },
				{ key: 'name', sortable: true, class: 'align-middle', tdClass: 'col-sets' },
				{ key: 'role', sortable: true, class: 'align-middle text-center' },
				{ key: 'lastlogin', label: 'Last Login', sortable: true, class: 'align-middle' },
				{ key: 'sitecount', label: 'Sites', sortable: true, class: 'align-middle' },
				{ key: 'actions', label: '', class: 'align-middle' },
			],
			sortBy: 'AccountID',
			sortDesc: false,

			searchText: '',
			currentPage: 1,
			loaded: false,
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		filteredAccounts() {
			if (!this.searchText) return this.accounts;
			return this.accounts.filter(account => {
				return (
					account.username.toLowerCase().includes(this.searchText.toLowerCase()) ||
					account.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
					account.email.toLowerCase().includes(this.searchText.toLowerCase()) ||
					account.AccountID.toString().includes(this.searchText.toString())
				);
			});
		},
	},
	methods: {
		processData(resp) {
			return resp.data.data;
		},
		rowClickHandler(record, index) {
			this.$router.push({ name: 'admineditaccount', params: { id: record.AccountID } });
		},
		clickViewAs(data) {
			this.$store.dispatch(ENABLE_VIEWAS, data).then(() => {
				this.$router.push({ name: 'dash' });
			});
		},
		deleteAccount(id) {
			this.$http
				.post('deleteaccount', { id: id })
				.then(res => {
					if (res.data.data) {
						let index = this.accounts.findIndex(acct => acct.AccountID === id);
						this.accounts.splice(index, 1);
					}
				})
				.catch(err => {
					console.log('Delete Acct Error:', err);
				});
		},
	},
	mounted() {
		this.loaded = false;
		this.$http.get('accounts').then(resp => {
			this.accounts = this.processData(resp);
			//this.$store.commit(SET_READY_STATE, { component: "SiteList", ready: true });
			this.loaded = true;
		});
		this.setSWListener('accounts', data => {
			this.accounts = this.processData(data);
		});
		this.searchText = this.$route.query.accountq;
	},
	watch: {
		searchText(newVal) {
			if (newVal !== this.$route.query.accountq) {
				this.$router.replace({ query: { ...this.$route.query, accountq: newVal || undefined } }).catch(err => {});
			}
		},
	},
};
</script>

<style scoped>
.search-box {
	position: relative;
}
.search-bar {
	padding-left: 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
.col-sets {
	max-width: 319px;
}
</style>
